import { parseUserAgent } from "../../../common/userAgent";
import { ActionTypeAppOS, ScenarioData, SolutionActionType } from "../../../types";
import {
  ScenarioVersionChildSolutionActionApp,
  ScenarioVersionChildSolutionActionPhoneNumber,
  ScenarioVersionChildSolutionActionPostalMailOrLocation,
  ScenarioVersionChildSolutionActionStore,
  ScenarioVersionChildSolutionIFrame,
} from "../../../types/api";
import { FAKE_PREVIEW_SCENARIO_SESSION_ID, STORAGE_SESSION_ID_KEY } from "../../constants";
import { scenarioParams } from "../../stores";
import {
  CodeNode,
  IFrameNode,
  IntermediateOpenAppNode,
  PhoneNumberNode,
  PostalAddressNode,
} from "../../ui";
import { getInSessionStorage } from "../analytics";
import { getIframePreviewParameters, parseIframeURL } from "../iframe";
import { getOsFromParsedUserAgent } from "../osUrl";

type RenderSolutionArgs = {
  solutionId: string;
  scenarioData: ScenarioData;
  onStoreOrAppClick: (os: "iOS" | "Android") => Promise<void>;
  previewMode: boolean;
};

export const renderSolution = ({
  solutionId,
  scenarioData,
  onStoreOrAppClick,
  previewMode,
}: RenderSolutionArgs) => {
  const { type, solution } = scenarioData;
  if (type === SolutionActionType.postalMail) {
    const { address1, address2, postalCode, city, country } =
      solution as ScenarioVersionChildSolutionActionPostalMailOrLocation;
    return (
      <PostalAddressNode
        address1={address1}
        address2={address2}
        postalCode={postalCode}
        city={city}
        country={country}
      />
    );
  } else if (
    type === SolutionActionType.ivrCallHead ||
    type === SolutionActionType.ivrCallOriented
  ) {
    const { phoneNumber, isDigicall, isFastDialEnabled, phoneCardText } =
      solution as ScenarioVersionChildSolutionActionPhoneNumber;
    return (
      <PhoneNumberNode
        phoneNumber={phoneNumber}
        userOs={getUserOs()}
        pinCode={scenarioParams.value.pinCode}
        isDigicall={isDigicall}
        isFastDialEnabled={isFastDialEnabled}
        phoneCardText={phoneCardText}
      />
    );
  } else if (type === SolutionActionType.store || type === SolutionActionType.app) {
    const { iosLink, introductionText, androidLink, playStoreLink, appStoreLink } =
      solution as ScenarioVersionChildSolutionActionStore & ScenarioVersionChildSolutionActionApp;

    return (
      <IntermediateOpenAppNode
        actionType={type}
        onClick={onStoreOrAppClick}
        iosLink={iosLink}
        introductionText={introductionText}
        playStoreLink={playStoreLink}
        appStoreLink={appStoreLink}
        androidLink={androidLink}
      />
    );
  } else if (type === SolutionActionType.iFrame) {
    const { url } = solution as ScenarioVersionChildSolutionIFrame;
    // In preview mode, there is no scenarioSession, we have to provide a fake one to be able to at least see the iframe
    // If we ever need to actually test fastform and actually submit the form, we should make the scenarioSessionId optional on fastform side
    const scenarioSessionId = previewMode
      ? FAKE_PREVIEW_SCENARIO_SESSION_ID
      : getInSessionStorage(STORAGE_SESSION_ID_KEY) ?? undefined;

    const previewParams = getIframePreviewParameters(solutionId);
    const parsedUrl = parseIframeURL(url, { ...previewParams, scenarioSessionId, solutionId });

    return parsedUrl ? <IFrameNode url={parsedUrl} /> : null;
  } else if (type === SolutionActionType.liveChat) {
    return (
      <CodeNode
        scenarioData={scenarioData}
        // This prop allows you to regularly modify the identifier in order to force the remounted of the component
        forceRemount={Math.floor(Date.now() / 1000)}
      />
    );
  } else {
    // eslint-disable-next-line
    console.error("Invalid solutionActionType");
    return null;
  }
};

function getUserOs(): ActionTypeAppOS {
  try {
    const parsedUserAgent = parseUserAgent(navigator.userAgent);
    return ActionTypeAppOS[getOsFromParsedUserAgent(parsedUserAgent)];
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.error("Failed to parse user agent : %o", e);
    return ActionTypeAppOS.other;
  }
}

import { z } from "zod";
import {
  baseBlockSchema,
  blockButtonsSchema,
  blockDotsSchema,
  blockLiveChatSchema,
  blockStepsSchema,
  blockTextSchema,
  chatCustomBlockSchema,
  chatFastActionSchema,
  chatMessageSchema,
  chatOpenCustomBlockSchema,
  liveChatContextSchema,
  metadataSchema,
} from "../schemas/chat";

export type BaseBlock = z.infer<typeof baseBlockSchema>;

export enum ChatCustomBlockTypes {
  bouncingDots = "bouncingDots",
  buttons = "buttons",
  liveChat = "liveChat",
  steps = "steps",
  text = "text",
  event = "events",
}

export enum DoChatAction {
  editBotResponse = "editBotResponse",
  newSourcesToAdd = "newSourcesToAdd",
  resetUsedSources = "resetUsedSources",
}

export type BlockSteps = z.infer<typeof blockStepsSchema>;

export type BlockButtons = z.infer<typeof blockButtonsSchema>;

export type BlockText = z.infer<typeof blockTextSchema>;

export type BlockDots = z.infer<typeof blockDotsSchema>;

export type BlockLiveChat = z.infer<typeof blockLiveChatSchema>;

export type ChatCustomBlock = z.infer<typeof chatCustomBlockSchema>;

export type ChatOpenCustomBlock = z.infer<typeof chatOpenCustomBlockSchema>;

export type ChatFastAction = z.infer<typeof chatFastActionSchema>;

export type ChatMessage = z.infer<typeof chatMessageSchema>;

export type LiveChatContext = z.infer<typeof liveChatContextSchema>;

export type Metadata = z.infer<typeof metadataSchema>;

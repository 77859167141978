import { parseUserAgent } from "../../../common/userAgent";
import {
  ActionTypeAppOS,
  DynamicUrls,
  PushWrapperFn,
  ScenarioAnalyticsDocumentActionType,
  ScenarioNavigationItem,
  SolutionActionType,
} from "../../../types";
import { STORAGE_HISTORY_IDX_KEY } from "../../constants";
import {
  checkIfUrlActionType,
  getInSessionStorage,
  getOsFromParsedUserAgent,
  handleContextualizedRedirection,
  handleEmailAction,
  handleIFrameAction,
  handleInternalFaqAction,
  handleIvrAction,
  handlePostalMailAction,
  handleSolutionAnalytic,
  handleStoreOrAppAction,
  handleUrlAction,
  postScenarioDetails,
  setInSessionStorage,
  toMongoId,
} from "../../helpers";
import { handleLiveChatAction } from "../router/handleLiveChatAction";

export type NavigationWithRouteArgs = {
  navigation: ScenarioNavigationItem[];
  routerPush: PushWrapperFn;
  shortCode: string | undefined;
  scenarioId: string | undefined;
  branch?: string;
  dynamicUrls: DynamicUrls;
};

export const getNavigationWithRouter = ({
  navigation,
  routerPush,
  shortCode,
  scenarioId,
  branch,
  dynamicUrls,
}: NavigationWithRouteArgs) => {
  return navigation.map((itemLink) => {
    let parsedUserAgent: ReturnType<typeof parseUserAgent> | undefined;
    try {
      parsedUserAgent = parseUserAgent(navigator.userAgent);
    } catch {
      parsedUserAgent = undefined;
    }
    const isNotAMobile = parsedUserAgent?.platform?.type !== 2;
    if (!scenarioId && !shortCode)
      // eslint-disable-next-line no-console
      return {
        ...itemLink,
        // eslint-disable-next-line no-console
        onClick: async () => console.error("Undefined scenarioId or shortCodeId"),
      };
    else if (itemLink.to && itemLink.to.solution) {
      const { solution, key: toPageKey } = itemLink.to;
      const { actionType } = solution;
      if (checkIfUrlActionType({ actionType })) {
        const { url } = solution;

        return {
          ...itemLink,
          onClick: async () => {
            // Post scenario details needs to be called directly here, as the router push function is not used in email or url solutions,
            // Therefore, the analytics part needs to be done manually
            await postScenarioDetails({
              type: ScenarioAnalyticsDocumentActionType.Push,
              shortCode,
              toPageKey,
              fromIntentId: itemLink.id,
            });
            const hasSolutionBeenExecuted = handleUrlAction({ url });
            if (hasSolutionBeenExecuted)
              await handleSolutionAnalytic({
                type: actionType,
                solutionId: solution.id,
                shortCode,
              });
          },
        };
      } else if (actionType === SolutionActionType.contextualizedRedirection && branch) {
        const { openType, location } = solution;
        return {
          ...itemLink,
          onClick: async () => {
            await postScenarioDetails({
              type: ScenarioAnalyticsDocumentActionType.Push,
              shortCode,
              toPageKey,
              fromIntentId: itemLink.id,
            });
            const hasSolutionBeenExecuted = handleContextualizedRedirection({
              branch,
              openType,
              location,
              dynamicUrls,
            });
            if (hasSolutionBeenExecuted)
              await handleSolutionAnalytic({
                type: actionType,
                solutionId: solution.id,
                shortCode,
              });
          },
        };
      } else if (actionType === SolutionActionType.email) {
        const { to: toEmail, subject, body, cc, bcc } = solution;
        return {
          ...itemLink,
          onClick: async () => {
            await postScenarioDetails({
              type: ScenarioAnalyticsDocumentActionType.Push,
              shortCode,
              toPageKey,
              fromIntentId: itemLink.id,
            });
            const hasSolutionBeenExecuted = handleEmailAction({
              to: toEmail,
              subject,
              body,
              cc,
              bcc,
            });
            if (hasSolutionBeenExecuted)
              await handleSolutionAnalytic({
                type: actionType,
                solutionId: solution.id,
                shortCode,
              });
          },
        };
      } else if (actionType === SolutionActionType.internalFAQ && solution.pageKey) {
        const { pageKey } = solution;
        return {
          ...itemLink,
          onClick: async () => {
            const hasSolutionBeenExecuted = await handleInternalFaqAction({
              pageKey,
              shortCode,
              scenarioId,
              routerPush,
              fromIntentId: itemLink.id,
            });
            if (hasSolutionBeenExecuted)
              await handleSolutionAnalytic({
                type: actionType,
                solutionId: solution.id,
                shortCode,
              });
          },
        };
      } else if (
        actionType === SolutionActionType.ivrCallHead ||
        actionType === SolutionActionType.ivrCallOriented
      ) {
        const { id, phoneNumber } = solution;
        const toPageKey = `solution-${id}`;
        return {
          ...itemLink,
          onClick: async () => {
            const hasSolutionBeenExecuted = await handleIvrAction({
              id,
              shortCode,
              scenarioId,
              phoneNumber,
              routerPush,
              toPageKey,
              fromIntentId: itemLink.id,
            });
            if (hasSolutionBeenExecuted)
              await handleSolutionAnalytic({
                type: actionType,
                solutionId: solution.id,
                shortCode,
              });
          },
        };
      } else if (actionType === SolutionActionType.app || actionType === SolutionActionType.store) {
        const { actionType, iosLink, androidLink, appStoreLink, id, playStoreLink } = solution;
        const toPageKey = `solution-${id}`;
        return {
          ...itemLink,
          onClick: async () => {
            const hasSolutionBeenExecuted = await handleStoreOrAppAction({
              isNotAMobile,
              id,
              shortCode,
              scenarioId,
              actionType: actionType as SolutionActionType.app | SolutionActionType.store,
              os: (parsedUserAgent
                ? getOsFromParsedUserAgent(parsedUserAgent)
                : "other") as ActionTypeAppOS,
              iosLink,
              appStoreLink,
              routerPush,
              androidLink,
              playStoreLink,
              toPageKey,
              fromIntentId: itemLink.id,
            });
            if (hasSolutionBeenExecuted)
              await handleSolutionAnalytic({
                type: actionType,
                solutionId: solution.id,
                shortCode,
              });
          },
        };
      } else if (actionType === SolutionActionType.postalMail) {
        const { id } = solution;
        const toPageKey = `solution-${id}`;
        return {
          ...itemLink,
          onClick: async () => {
            const hasSolutionBeenExecuted = await handlePostalMailAction({
              id,
              shortCode,
              scenarioId,
              routerPush,
              toPageKey,
              fromIntentId: itemLink.id,
            });
            if (hasSolutionBeenExecuted)
              await handleSolutionAnalytic({
                type: actionType,
                solutionId: solution.id,
                shortCode,
              });
          },
        };
      } else if (actionType === SolutionActionType.liveChat) {
        const { url } = solution;
        return {
          ...itemLink,
          onClick: async () => {
            handleLiveChatAction({ url });
            /* Two cases are possible, if it is an Iadvize solution, the helper above won't do anything,
      but we still have to send the analytics, so solutionHasBeenExecuted must be set to true.  */
            await handleSolutionAnalytic({
              type: actionType,
              solutionId: solution.id,
              shortCode,
            });
          },
        };
      } else if (actionType === SolutionActionType.iFrame) {
        const { id } = solution;
        const toPageKey = `solution-${id}`;
        return {
          ...itemLink,
          onClick: async () => {
            const hasSolutionBeenExecuted = await handleIFrameAction({
              id,
              shortCode,
              scenarioId,
              routerPush,
              toPageKey,
              fromIntentId: itemLink.id,
            });
            // This allows to send the analytics only if the Iframe is not a FastForm iframe. We do this because the fastForm service internally manages the analytics.
            if (hasSolutionBeenExecuted)
              await handleSolutionAnalytic({
                type: actionType,
                solutionId: solution.id,
                shortCode,
              });
          },
        };
      } else {
        // eslint-disable-next-line no-console
        return { ...itemLink, onClick: async () => console.error("Unknown error") };
      }
    } else if (itemLink && itemLink.to && itemLink.to.key) {
      const url = shortCode
        ? `/c/${shortCode}/${itemLink.to.key}`
        : `/p/${scenarioId}/${itemLink.to.key}`;
      return {
        ...itemLink,
        onClick: async () =>
          routerPush(url, {
            toPageKey: itemLink.to?.key,
            fromIntentId: itemLink.id,
          }),
      };
    } else {
      return {
        ...itemLink,
        // eslint-disable-next-line no-console
        onClick: async () => console.error("Invalid navigationItem"),
      };
    }
  });
};

type HandleHomeButtonClickArgs = {
  shortCode?: string;
  scenarioId?: string;
  mainPage: { id: string; key: string };

  routerPush: PushWrapperFn;
};
export const handleHomeButtonClick = async ({
  shortCode,
  scenarioId,
  mainPage,
  routerPush,
}: HandleHomeButtonClickArgs) => {
  const url = shortCode ? `/c/${shortCode}/${mainPage.key}` : `/p/${scenarioId}/${mainPage.key}`;
  await routerPush(url, { toPageKey: mainPage.key });
};

type HandleBackButtonClickArgs = {
  back: () => void;
  pageKey?: string;
  solutionId?: string;
  shortCode?: string;
};
export const handleBackButtonClick = async ({
  back,
  pageKey,
  solutionId,
  shortCode,
}: HandleBackButtonClickArgs) => {
  // Allow to make the navigation index history persitent in order to manage the back button
  const historyIdx = getInSessionStorage(STORAGE_HISTORY_IDX_KEY);
  if (historyIdx && historyIdx !== "0") {
    const historyIdxNumber: number = parseInt(historyIdx) - 1;
    setInSessionStorage(STORAGE_HISTORY_IDX_KEY, historyIdxNumber.toString());
  }

  back();
  const fromPageKey = pageKey || (solutionId ? `solution-${toMongoId(solutionId)}` : undefined);
  const toPageKey = window.history.state.options.previousPageKey;
  if (fromPageKey && toPageKey && shortCode)
    await postScenarioDetails({
      shortCode,
      fromPageKey,
      toPageKey,
      type: ScenarioAnalyticsDocumentActionType.Back,
    });
};

type HandleCloseButtonClickArgs = {
  shortCode?: string;
  toPageKey?: string;
  solutionId?: string;
};

export const handleCloseButtonClick = async ({
  shortCode,
  toPageKey,
  solutionId,
}: HandleCloseButtonClickArgs) => {
  const page = toPageKey || (solutionId ? `solution-${toMongoId(solutionId)}` : undefined);
  try {
    await postScenarioDetails({
      type: ScenarioAnalyticsDocumentActionType.Close,
      shortCode,
      toPageKey: page,
    });
  } finally {
    window.parent.postMessage({ action: "dialonce-close" }, "*");
  }
};

import ColorizeIcon from "@mui/icons-material/Colorize";
import {
  InputAdornment,
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
  Popover,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { MouseEvent, ReactNode, forwardRef, useCallback, useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import useEyeDropper from "use-eye-dropper";
import { doTheme } from "../../doTheme";
import { TextFieldType } from "../../types";

export type TextFieldProps = Omit<
  MUITextFieldProps,
  "size" | "color" | "variant" | "select" | "margin" | "type"
> & {
  labelStyle?: "default" | "top";
  type?: TextFieldType;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  ariaLabel?: string;
  readOnly?: boolean;
  shrink?: boolean;
  disableTrim?: boolean;
};

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      type = "text",
      InputProps: incomingInputProps,
      InputLabelProps: incomingInputLabelProps,
      label,
      labelStyle = "default",
      placeholder,
      startAdornment,
      endAdornment,
      ariaLabel,
      readOnly,
      shrink,
      disableTrim = false,
      sx,
      value,
      onChange,
      onBlur,
      ...props
    },
    ref
  ) => {
    if (type === "color") {
      if (value === undefined || value === null) {
        throw new Error('TextField with type "color" requires "value" prop');
      }

      if (typeof value !== "string") {
        throw new Error('TextField with type "color" requires "value" prop to be a string');
      }
    }

    const shrinkTypes = ["date", "datetime-local", "month", "time", "week", "color"];

    const isShrink =
      Boolean(placeholder && label) ||
      shrinkTypes.includes(type) ||
      labelStyle === "top" ||
      incomingInputLabelProps?.shrink ||
      shrink
        ? true
        : undefined;

    const InputProps: MUITextFieldProps["InputProps"] & {
      notched?: boolean;
    } = {
      ...incomingInputProps,
    };

    if (ariaLabel) {
      InputProps["aria-label"] = ariaLabel;
    }

    if (labelStyle === "top") {
      InputProps.notched = false;
    }

    if (readOnly) {
      InputProps.readOnly = true;
    }

    if (startAdornment) {
      InputProps.startAdornment = (
        <InputAdornment position="start">{startAdornment}</InputAdornment>
      );
    }

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "color-picker-popover" : undefined;
    const { open: openEyeDrop, isSupported: isEyeDropSupported } = useEyeDropper();
    const pickColor = useCallback(() => {
      const openDropperPicker = async () => {
        try {
          const color = await openEyeDrop();
          const nativeEvent = new Event("change", {
            bubbles: true,
            cancelable: true,
          });

          Object.defineProperty(nativeEvent, "target", {
            value: { value: color.sRGBHex },
            writable: false,
          });

          onChange && onChange(nativeEvent as unknown as React.ChangeEvent<HTMLInputElement>);
        } catch (e) {
          //
        }
      };
      void openDropperPicker();
    }, [openEyeDrop, onChange]);

    if (type === "color") {
      InputProps.endAdornment = (
        <InputAdornment position="end">
          <div
            aria-describedby={id}
            style={{
              backgroundColor: (value as string) || "#000000",
              borderRadius: doTheme.shape.borderRadius,
              cursor: "pointer",
              width: 25,
              height: 25,
              boxShadow: doTheme.shadows[2],
            }}
            onClick={handleClick}
          />

          <Popover
            id={id}
            elevation={2}
            sx={{
              marginTop: "15px",
              "& .MuiPaper-root": {
                borderRadius: 2,
              },
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div>
              <ChromePicker
                color={value as string}
                onChange={(color: ColorResult) => {
                  const nativeEvent = new Event("change", {
                    bubbles: true,
                    cancelable: true,
                  });

                  Object.defineProperty(nativeEvent, "target", {
                    value: { value: color.hex },
                    writable: false,
                  });

                  onChange &&
                    onChange(nativeEvent as unknown as React.ChangeEvent<HTMLInputElement>);
                }}
                disableAlpha
              />
            </div>
          </Popover>
          {isEyeDropSupported() && (
            <IconButton onClick={pickColor}>
              <ColorizeIcon />
            </IconButton>
          )}
        </InputAdornment>
      );
    } else if (endAdornment) {
      InputProps.endAdornment = <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }

    return (
      <MUITextField
        ref={ref}
        type={type === "color" ? "text" : type}
        size="small"
        variant="outlined"
        label={label}
        placeholder={placeholder}
        InputProps={InputProps}
        value={value}
        InputLabelProps={{
          shrink: isShrink,
          ...incomingInputLabelProps,
        }}
        sx={{
          ...(labelStyle === "top" && {
            "& .MuiFormLabel-root": {
              position: "unset",
              transform: "none",
              transition: "none",
              marginBottom: "5px",
              fontSize: "16px",
              color: "black",

              "&.Mui-focused": {
                color: "black",
              },
            },
          }),
          ...(type === "color" && {
            "& .MuiInputBase-root": {
              paddingRight: "8px",
            },
            "& .MuiInputBase-input": {
              textTransform: "uppercase",
            },
          }),
          ...sx,
        }}
        onChange={(event) => {
          if (type === "color") {
            let value = event.target.value;

            if (!value.startsWith("#")) {
              value = "#" + value;
            }

            value = value.slice(0, 7);

            Object.defineProperty(event, "target", {
              value: { value: value },
              writable: false,
            });
          }
          onChange && onChange(event);
        }}
        onBlur={(event) => {
          if (type === "color") {
            const value = event.target.value;
            const hexRegex = /^#[0-9A-Fa-f]{6}$/;

            if (!hexRegex.test(value)) {
              Object.defineProperty(event, "target", {
                value: { value: "#000000" },
                writable: false,
              });
            }
          }

          if (!disableTrim) {
            const value = event.target.value;
            Object.defineProperty(event, "target", {
              value: { value: value.trim() },
              writable: false,
            });
          }

          onBlur && onBlur(event);
        }}
        {...props}
      />
    );
  }
);

export default TextField;

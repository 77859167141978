import { z } from "zod";

const dochatConfigIdMissingStr = "Dochat config id cannot be empty";

const dochatConfigNameMissingStr = "Dochat config name cannot be empty";

const sessionIdInvalidLength = "Session id must be 24 characters long";

export const dochatConfigIdSchema = z
  .string({
    required_error: dochatConfigIdMissingStr,
  })
  .trim()
  .min(1, { message: dochatConfigIdMissingStr });

export const dochatConfigSchema = z.object({
  id: dochatConfigIdSchema,
  name: z
    .string({ required_error: dochatConfigNameMissingStr })
    .trim()
    .min(1, { message: dochatConfigNameMissingStr }),
  orchestratorId: z.string().optional(),
  // If translate endpoint, don't forget to populate defaultModelLanguage in chatbotParams
  rasaUrl: z.string(),
});

export const sessionIdSchema = z.string().length(24, { message: sessionIdInvalidLength });

export const localeSchema = z.string().min(2).max(5);
export type Locale = z.infer<typeof localeSchema>;

const localeConfigSchema = z.object({
  disclaimerText: z.string(),
  isDefault: z.boolean(),
  placeholder: z.string(),
  // Only if dochatConfigSchema.rasaUrl is a translate endpoint
  rasaUrl: z.string().optional(),
  title: z.string(),
});
export type LocaleConfig = z.infer<typeof localeConfigSchema>;

export const conversationContextSchema = z
  .object({
    brand: z.string().nullable(),
    data: z.record(z.string(), z.string()).nullable(),
    entities: z.record(z.string(), z.string()).nullable(),
    externalRef: z.string().nullable(),
    intent: z.string().nullable(),
    langage: z.string().nullable(),
    startMessage: z.string().nullable(),
  })
  .partial();

export const chatbotParamsSchema = localeConfigSchema
  .omit({ isDefault: true, rasaUrl: true })
  .extend({
    availableLocales: z.record(localeSchema, localeConfigSchema).optional(),
    colorBackgroundMessages: z.string(),
    colorPrimary1: z.string().regex(/^#[0-9a-fA-F]{3,6}$/),
    colorPrimary2: z.string().regex(/^#[0-9a-fA-F]{3,6}$/),
    // Only if dochatConfigSchema.rasaUrl is a translate endpoint
    //  and there's no localeConfigSchema.rasaUrl
    defaultModelLanguage: localeSchema.optional(),
    disclaimerActivate: z.boolean(),
    disclaimerBgcolor: z.string(),
    disclaimerPosition: z.literal("top").or(z.literal("bottom")),
    disclaimerText: z.string(),
    enableCloseButton: z.boolean().optional(),
    showPoweredByDialonce: z.boolean().optional(),
    enableRefreshButton: z.boolean().optional(),
    fontId: z.string(),
    logoDataUri: z.string(),
    noUserInput: z.boolean().optional(),
    placeholder: z.string(),
    rasaContext: z.record(z.string(), conversationContextSchema).optional(),
    simulateStreaming: z.boolean(),
    stylesheets: z.record(z.string(), z.string()),
    title: z.string().min(3).max(250),
    // This any will be replaced by a more specific type when we remove cute or we know the type
    staticContextsMap: z.record(z.string(), z.any()).optional(),
  })
  .partial();

export const exampleCompanySiteUrlSchema = z.string().url().nullable();

export const APIDochatConfigSchema = z
  .object({
    brandFromQParams: z.string().optional().nullable(),
    callingURL: z.string().optional().nullable(),
    chatbotStaticCtxMapKey: z.string().optional().nullable(),
    digitalDynamicCtx: conversationContextSchema.optional().nullable(),
    id: dochatConfigIdSchema,
    pinCode: z.string().optional().nullable(),
    positionId: z.string().optional().nullable(),
    sessionId: sessionIdSchema.nullish(),
    theming: z.string().optional().nullable(),
    userLang: z.string().optional().nullable(),
  })
  .required();

export const APIDochatConfigWithoutSessionSchema = z
  .object({ id: dochatConfigIdSchema })
  .required();

export const APIChatbotParamsSchema = z.object({
  id: dochatConfigIdSchema,
  chatbotParams: chatbotParamsSchema,
});

export const APIConversationStartParamsSchema = z
  .object({
    analyticId: z.string(),
    chatbotParams: chatbotParamsSchema,
    company_cuid: z.string().min(1),
    computedBrand: z.string().optional().nullable(),
    computedLangage: z.string().optional().nullable(),
    computedStartMessage: z.string(),
    computedStaticData: z.record(z.string(), z.string()).nullable(),
    createdPinCode: z.string().optional().nullable(),
    id: dochatConfigIdSchema,
    orchestrator_id: z.string().min(1),
    rasaUrl: z.string().min(1),
    outOfCredits: z.boolean().optional(),
  })
  .required();

export const contrackResponseSchema = z.object({
  context: conversationContextSchema,
});

export type APIDochatConfig = z.infer<typeof APIDochatConfigSchema>;
export type APIDochatConfigWithoutSession = z.infer<typeof APIDochatConfigWithoutSessionSchema>;
export type ChatbotParams = z.infer<typeof chatbotParamsSchema>;
export type ConversationContext = z.infer<typeof conversationContextSchema>;
export type ConversationStartParams = z.infer<typeof APIConversationStartParamsSchema>;
export type DochatConfig = z.infer<typeof dochatConfigSchema>;
export type DochatConfigId = z.infer<typeof dochatConfigIdSchema>;
export type ExampleCompanySiteUrl = z.infer<typeof exampleCompanySiteUrlSchema>;

import { ActionIvr } from "../../../types/common";
import { PushWrapperFn } from "../../../types/scenarioAnalytics";
import { context, scenarioParams } from "../../stores";
import { toGlobalId } from "../globalId";
import { updateContrackContext } from "../requests";

type HandleIvrActionArgs = {
  id: string;
  shortCode: string | undefined;
  scenarioId: string | undefined;
  routerPush: PushWrapperFn;
  toPageKey?: string;
  fromIntentId?: string;
} & ActionIvr;

export const handleIvrAction = async ({
  id,
  shortCode,
  scenarioId,
  phoneNumber,
  routerPush,
  toPageKey,
  fromIntentId,
}: HandleIvrActionArgs) => {
  if (phoneNumber) {
    if (scenarioParams.value.pinCode) {
      if (shortCode) {
        await updateContrackContext(shortCode, scenarioParams.value.pinCode, context.value, true);
      } else {
        // eslint-disable-next-line no-console
        console.error("handleIvrAction : Shortcode is not defined, cannot sync and push context");
      }
    }
    const encodedId = toGlobalId("ScenarioVersionChildSolution", id);
    const url = shortCode ? `/c/${shortCode}/s/${encodedId}` : `/p/${scenarioId}/s/${encodedId}`;
    await routerPush(url, { toPageKey, fromIntentId });
    return true;
  } else {
    // eslint-disable-next-line no-console
    console.error("No phone number given");
    return false;
  }
};

import type { AxiosRequestConfig } from "axios";

export enum FsmDefMetaTypes {
  axios = "axios",
  doBot = "doBot",
  form = "form",
  liveChat = "liveChat",
  markdown = "markdown",
}

export type FsmDefAxiosState = {
  meta: {
    type: FsmDefMetaTypes.axios;
    options: AxiosRequestConfig;
  };
  on: {
    success: {
      target: string;
    };
    error: {
      target: string;
    };
  };
};

export type FsmDefDoBotState = {
  meta: {
    type: FsmDefMetaTypes.doBot;
    action: string;
    payloadTransformer: string;
  };
};

export type FsmDefFormState = {
  meta: {
    type: FsmDefMetaTypes.form;
    formSchema: object;
  };
  on: {
    formOnSubmit: {
      target: string;
    };
  };
};

export type FsmDefLiveChatState = {
  meta: {
    type: FsmDefMetaTypes.liveChat;
    liveChatConfigId: string;
    payloadTransformer: string;
  };
};

export type FsmDefMarkdownState = {
  meta: {
    type: FsmDefMetaTypes.markdown;
    markdown: string;
  };
};
